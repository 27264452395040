import React, { useContext } from "react";
import { IconContext } from "react-icons";
import { FaUserCircle } from "react-icons/fa";
import { StoreContext } from "../../utlis/StoresContact";
import { getAuth, signOut } from "firebase/auth";

const Profile = (props) => {
  const { current } = useContext(StoreContext);
  const auth = getAuth();

  return (
    <>
      <div className="home_middle_bottom">
        <div className="profile_container">
          <div className="row">
            <IconContext.Provider value={{ color: "grey", size: 50 }}>
              <div className="home_middle_sub_icons">
                <FaUserCircle className="navbar_icons" />
              </div>
            </IconContext.Provider>

            <div className="col-5">
              <h5>{localStorage.getItem("customerName")}</h5>
              <h6>Edit Profile Picture | Edit Profile</h6>
            </div>
            <div className="col">
              <div className="row profile_btn">
                <button className="profile_btn_setting">Settings</button>
                <button
                  className="profile_btn_signout"
                  onClick={() => {
                    signOut(auth)
                      .then(() => {})
                      .catch((error) => {});
                    window.location.reload();
                  }}
                >
                  Sign Out
                </button>
              </div>
            </div>
          </div>
          <div className="">
            <div className="row profile_div">
              <button
                className="profile_category"
                onClick={() => current[1](14)}
              >
                Your Orders
              </button>
              <button
                className="profile_category"
                onClick={() => current[1](13)}
              >
                Saved Addresses
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
