import React, { useContext, useEffect, useState } from "react";
import { FaUserCircle } from "react-icons/fa";
import { AiOutlineShoppingCart, AiOutlineHeart } from "react-icons/ai";
import { IconContext } from "react-icons";
import MultiNavbar from "./MultiNabar";
import { StoreContext } from "../utlis/StoresContact";
import { getDocs, collection, limit, query } from "firebase/firestore";
import { db } from "../Firebase";
import { getAuth } from "firebase/auth";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import CircularProgressIndicator from "./CircularProgressIndicator";
import anandLogo from "../images/anand logo.png";
import Footer from "./Footer";
import MultiCarousel from "./MultiCarousel";
import { AppBar } from "@mui/material";

function Home() {
  const auth = getAuth();
  const user = auth.currentUser;
  const { current, Pages, searchItem, searchfunction, category } =
    useContext(StoreContext);
  const [banner, setBanner] = useState();
  const [isloading, setIsLoading] = useState(true);
  const [categorys, setCategorys] = useState();

  const getCategory = async () => {
    const categorySnapshot = await getDocs(collection(db, "categories"));
    const categoryList = categorySnapshot.docs.map((doc) => doc.data());
    console.log(categoryList);
    setCategorys(categoryList);
  };
  const getBanner = async () => {
    const bannerSnapshot = await getDocs(
      query(collection(db, "banners"), limit(5))
    );
    const bannerList = bannerSnapshot.docs.map((doc) => doc.data());

    setBanner(bannerList);
  };
  const updateState = (value) => {
    current[1](value);
  };
  const getData = async () => {
    await getBanner();
    await getCategory();
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div className="home_container">
      {isloading ? (
        <div>
          <CircularProgressIndicator />
        </div>
      ) : (
        <>
          <div className="home_middle">
            <AppBar 
             position="sticky"
             elevation={0}
             sx={{
               bgcolor: "#ffffff",
                
             }}
            >            <div className="navbar-row">
              {/* logo */}
              <img
                src={anandLogo}
                alt=""
                className="anandlogo_img"
                onClick={() => {
                  current[1](0);
                }}
              />

              <div className="home_navbar_links">
                {/* middle menu */}
                <div id="navbar_sidebar">
                  <MultiNavbar />
                </div>

                {/* like buttons */}
                <div className="linkbuttons_home">
                  <IconContext.Provider value={{ color: "grey", size: 30 }}>
                    <AiOutlineHeart
                      className="navbar_icons"
                      onClick={() => {
                        current[1](11);
                      }}
                    />
                    <AiOutlineShoppingCart
                      className="navbar_icons"
                      onClick={() => {
                        current[1](8);
                      }}
                    />
                    <FaUserCircle
                      className="navbar_icons"
                      onClick={() => {
                        if (user) {
                          current[1](2);
                        } else {
                          current[1](9);
                        }
                      }}
                    />
                  </IconContext.Provider>
                </div>
              </div>
            </div>
            </AppBar>

            <div className="search_bar">
              <Paper
                component="form"
                sx={{
                  p: "1px 4px",
                  display: "flex",
                  alignItems: "center",
                  width: "80%",
                  boxShadow: "1px 1px 10px 1px #fff201",
                }}
              >
                <InputBase
                  sx={{ ml: 1, flex: 1 }}
                  placeholder="Search here"
                  inputProps={{ "aria-label": "search here" }}
                  onChange={(e) => {
                    searchItem[1](e.target.value);
                  }}
                />
                <IconButton
                  type="submit"
                  sx={{ p: "10px" }}
                  aria-label="search"
                  onClick={(e) => {
                    e.preventDefault();
                    searchfunction[0]();
                    current[1](15);
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </Paper>
            </div>
            <br />
            <div className="carousel__container">
              <div className="carousel__container_div">
                <MultiCarousel
                  names={categorys}
                  isClickable={true}
                  setCurrent={updateState}
                  setCategorys={category[1]}
                />
              </div>
            </div>
            {Pages[current[0]]}
          </div>
          <Footer />
        </>
      )}
    </div>
  );
}
export default Home;
