import React from "react";
import { useNavigate } from "react-router-dom";

export default function SignInButton() {
  let navigate = useNavigate();
  return (
    <>
      <div className="home_middle_bottom">
        <div className="not_logged_in">
          <button className="login_btn" onClick={() => navigate("/login")}>
            Login
          </button>
        </div>
      </div>
    </>
  );
}
