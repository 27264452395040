import React from "react";
import { Carousel } from "react-bootstrap";

const CarouselBanner = ({ urls }) => {
  return (
    <div className="carousel__container">
      <Carousel fade indicators={false}>
        {urls.map((item) => {
          return (
            <Carousel.Item key={item.bannerId}>
              <img
                className="carousel__container_image"
                src={`${item.url}`}
                alt={item.bannerId}
                fit={true}
              />
            </Carousel.Item>
          );
        })}
      </Carousel>
    </div>
  );
};

export default CarouselBanner;
