import React from "react";

function Filter({ lowToHigh, highToLow, relevance }) {
  
  return (
    <div>
      <div className="selection_items">
        <select
          className="form-select form-select-lg mb-3 select_filter"
          aria-label=".form-select-lg example"
          onChange={(e) => {
            if (e.target.value === "1") {
              relevance();
            } else if (e.target.value === "2") {
              lowToHigh();
            } else {
              highToLow();
            }
          }}
        >
          <option value={1}>Relevance</option>
          <option value={2}>Low to High</option>
          <option value={3}> High to Low</option>
        </select>
      </div>
    </div>
  );
}

export default Filter;
