import React, { useState, useEffect } from "react";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
} from "firebase/firestore";
import { getAuth } from "firebase/auth";
import { db } from "../Firebase.js";
import CircularProgressIndicator from "./CircularProgressIndicator";

export default function AddToFavourite() {
  const [loading, setLoading] = useState(true);
  const [favourite, setFavourite] = useState();

  const getFavouriteFromFirebase = async () => {
    let auth = getAuth();
    let userNumber = auth.currentUser.phoneNumber.substring(3, 13);
    const docRef = collection(db, "customers", userNumber, "favourites");
    const check = await getDocs(docRef);
    let favouriteList = check.docs.map((s) => s.data());
    setFavourite(favouriteList);
    setLoading(false);
  };

  useEffect(() => {
    getFavouriteFromFirebase();
  }, []);
  return (
    <>
      <div className="home_middle_bottom">
        <div className="favourite_container">
          {loading ? (
            <div>
              <CircularProgressIndicator />
            </div>
          ) : (
            <>
              {favourite.map((favdata) => (
                <>
                  {favdata.productItem.map((minidata) => (
                    <>
                      <div className="card product_list_fav">
                        <div className="img_container">
                          <div className="card_img_div">
                            <img
                              src={favdata.productImage}
                              className="cart-img-top"
                              alt="..."
                              // style={{
                              //   height: "6.5rem",
                              // }}
                            />
                          </div>
                          <div className="card_content_div">
                            <h5 className="card_title">
                              {favdata.productName}
                            </h5>
                            <h5 className="card_text">
                              Rs.{minidata.productPrice}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ))}
            </>
          )}
        </div>
      </div>
    </>
  );
}
