import React, { useState, useContext } from "react";
import { db, storage } from "../Firebase";
import { useNavigate } from "react-router-dom";
import { StoreContext } from "../utlis/StoresContact";
import {
  ref as storageRef,
  getDownloadURL,
  uploadBytesResumable,
} from "firebase/storage";
import { doc, setDoc } from "firebase/firestore";
import anandlogoImg from "../images/anand logo.png";
import CircularProgressIndicator from ".././Components/CircularProgressIndicator";

function Signup() {
  let navigate = useNavigate();
  const { phonenumber } = useContext(StoreContext);
  const [name, setName] = useState();
  const [img, setImg] = useState();
  const [isloading, setIsLoading] = useState(false);

  const imgHandler = (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    setImg(file);
  };
  const submitHandle = async () => {
    setIsLoading(true);
    try {
      const sotrageRef = storageRef(storage, `profilephoto/${img.name}`);
      const uploadTask = await uploadBytesResumable(sotrageRef, img);
      const url = await getDownloadURL(uploadTask.ref);
      await setDoc(doc(db, "customers", phonenumber[0]), {
        customerName: name,
        customerPhoneNumber: phonenumber[0],
        customerProfilePhoto: url,
        wallet: 100,
      });
      navigate("/");
    } catch (e) {}
    setIsLoading(false);
  };

  return (
    <>
      <div className="signup_container">
        {isloading ? (
          <div>
            <CircularProgressIndicator />
          </div>
        ) : (
          <>
          
      <div className="signup__main__cotainer">
            <div className="signup_main">
              <form>
                <div className="signup__container "> 
                <img src={anandlogoImg} alt="" height={100} width={200} />
                <br />
                <h2 className="signup_header ">Profile SetUp</h2>
                <label>Name: </label>
                <input
                  type="text"
                  placeholder="Enter your Name"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
                <br />
                <label>Profile Photo:</label>
                <input type="file" onChange={imgHandler} />
                <br />

                <button
                  className="signup_btn"
                  onClick={(e) => {
                    e.preventDefault();
                    submitHandle();
                  }}
                >
                  Submit
                </button>
                </div>
              </form>
            </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default Signup;
