import React, { useState, useContext, useEffect } from "react";
import {  collection,  getDocs } from "firebase/firestore";
import { db } from "../Firebase";
import { getAuth } from "firebase/auth";
import { StoreContext } from "../utlis/StoresContact";
import CircularProgressIndicator from "./CircularProgressIndicator";

function AddAddressWithoutCart({ number }) {
  const auth = getAuth();
  const [isloading, setIsLoading] = useState(true);
  const [addresslist, setAddressList] = useState();
  const [isselected, setIsSelected] = useState(0);
  const { current, address } = useContext(StoreContext);

  const getaddress = async () => {
    let userNumber = auth.currentUser.phoneNumber.substring(3, 13);
    const docRef = await collection(db, "customers", userNumber, "address");
    const check = await getDocs(docRef);
    const addressList = check.docs.map((s) => s.data().address);

    setAddressList(addressList);
    setIsLoading(false);
  };

  useEffect(() => {
    getaddress();
  }, []);

  return (
    <>
      <div className="home_middle_bottom">
        {isloading ? (
          <div>
            <CircularProgressIndicator />
          </div>
        ) : (
          <>
            <div className="address_header">
              <div className="row address_div">
                <div className="col">
                  <h3>Saved Addresses</h3>
                </div>
                <div className="col address_btn_div">
                  <button
                    className="address_btn"
                    onClick={() => {
                      current[1](10);
                    }}
                  >
                    + Add New Address
                  </button>
                </div>
              </div>
            </div>
            <div>
              {addresslist &&
                addresslist.map((add, index) => (
                  <>
                    <div
                      className="address_container"
                      onClick={(e) => {
                        e.preventDefault();
                        address[1](addresslist[index]);
                        setIsSelected(index);
                      }}
                    >
                      <div
                        className={
                          isselected === index
                            ? "address_card_green"
                            : "address_card"
                        }
                      >
                        <h6 className="address_card_content">{add}</h6>
                      </div>
                    </div>
                  </>
                ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
export default AddAddressWithoutCart;
