import React from "react";
import AboutYearImg from "../images/About image group-1.png";

function About() {
  return (
    <div className="home_middle_bottom">
      <h3 className="about_header">About Us</h3>
      <div className="about_middle">
        <img src={AboutYearImg} alt="" />
        <h3 className="about_middle_header">
          Anand General Store is Delhi's most beloved online grocery shopping
          platform. Our app is changing the way customers approach their daily
          essentials. You can now shop online for groceries, procured daily,
          dairy & bakery, beauty & wellness, personal care, household care,
          diapers & baby care, pet care, Ready to Eat & Frozen food products, as
          well as the latest products from leading brands like Cadbury, ITC,
          Colgate - Palmolive, PepsiCo, Aashirvaad, Saffola, Fortune, Nestle,
          Amul. Dabur, and well known Imported Brands.Imagine if you could get
          anything delivered to you in minutes. Milk for your morning chai. The
          perfect shade of lipstick for tonight's party. Even an iPhone. Our
          superfast delivery service aims to help consumers in India save time
          and fulfill their needs in a way that is frictionless. We will make
          healthy, high-quality and life-improving products available to
          everyone instantly so that people can have time for the things that
          matter to them.Established in 1977 by Shree Ram Goyal, Anand General Store was set up
          as India’s First Supermarket selling imported food products in the
          Khan Market, New Delhi. Last 5 decades, Anand General Store has
          created itself a name as the One Stop place for all essential and
          exclusive grocery and food products in Delhi and NCR.
        </h3>
      </div>
    </div>
  );
}

export default About;
