import React from 'react';

function Map() {
    return (
        <div id="map">
            <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3502.9924372329365!2d77.22452821479074!3d28.600003692303886!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce2e830b10cf9%3A0x131f4640947a60b4!2sAnand%20General%20Store!5e0!3m2!1sen!2sin!4v1652287018083!5m2!1sen!2sin"
                frameBorder="0"
                style={{
                    border: '0',
                    width: '100%',
                    height: '50vh',
                }}
                allowFullScreen=""
                aria-hidden="false"
            />
        </div>
    );
}

export default Map;
