import React, { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Form, Alert, Button } from "react-bootstrap";
import PhoneInput from "react-phone-number-input/input";
import { app } from "../Firebase";
import "react-phone-number-input/style.css";
import { db } from "../Firebase";
import { doc, getDoc } from "firebase/firestore";
import { StoreContext } from "../utlis/StoresContact";
import anandlogoImg from "../images/anand logo.png";
import {
  getAuth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";

const Login = () => {
  let navigate = useNavigate();
  const { phonenumber } = useContext(StoreContext);
  const [error, setError] = useState("");
  const [number, setNumber] = useState("");
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState("");
  const [result, setResult] = useState("");
  const auth = getAuth(app);
  const user = auth.currentUser;

  const handleNavigation = (link) => {
    navigate(link);
  };
  const getUserNo = async () => {
    const userSnapshot = await getDoc(
      doc(db, "customers", `${number.substring(3, 13)}`)
    );

    phonenumber[1](number.substring(3, 13));

    if (userSnapshot.exists() === true) {
      localStorage.setItem("customerName", userSnapshot.data().customerName);
      navigate(`/`);
      window.location.reload();
    } else {
      handleNavigation(`/signup`);
    }
  };

  function setUpRecaptha(number) {
    const recaptchaVerifier = new RecaptchaVerifier(
      "recaptcha-container",
      {},
      auth
    );
    recaptchaVerifier.render();
    return signInWithPhoneNumber(auth, number, recaptchaVerifier);
  }

  const getOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (number === "" || number === undefined)
      return setError("Please enter a valid phone number!");
    try {
      //for signup checking with document id oc customer

      const response = await setUpRecaptha(number);
      setResult(response);
      setFlag(true);
    } catch (err) {
      setError(err.message);
    }
  };

  const verifyOtp = async (e) => {
    e.preventDefault();
    setError("");
    if (otp === "" || otp === null)
      return setError("Please enter a valid OTP!");
    try {
      await result.confirm(otp);
      await getUserNo();
    } catch (err) {
      setError(err.message);
    }
  };

  const checkData = async () => {
    if (user) {
      navigate("/");
    } else {
      navigate("/login");
    }
  };
  useEffect(() => {
    checkData();
  }, []);

  return (
    <>
      <div className="login_main">
        <div className="login__container">
          <img src={anandlogoImg} alt="" height={100} width={200} />
          <br />
          <h2 className="mb-3 login__header">Sign In/Sign Up</h2>
          {/* {error && <Alert variant="danger">{error}</Alert>} */}
          <Form onSubmit={getOtp} style={{ display: !flag ? "block" : "none" }}>
            <Form.Group className="mb-3" controlId="formBasicEmail">
              <PhoneInput
                country="IN"
                defaultCountry="IN"
                value={number}
                onChange={setNumber}
                placeholder="Enter Phone Number"
              />

              <br />
              <br />
              <div id="recaptcha-container"></div>
            </Form.Group>
            <br />
            <br />
            <div className="button-right">
              <Link to="/">
                <Button variant="secondary">Cancel</Button>
              </Link>
              &nbsp;
              <Button type="submit" variant="primary">
                Send Otp
              </Button>
            </div>
          </Form>

          <Form
            onSubmit={verifyOtp}
            style={{ display: flag ? "block" : "none" }}
          >
            <Form.Group className="mb-3" controlId="formBasicOtp">
              <Form.Control
                type="otp"
                placeholder="Enter OTP"
                onChange={(e) => setOtp(e.target.value)}
              />
            </Form.Group>
            <div className="button-right">
              <Link to="/">
                <Button variant="secondary">Cancel</Button>
              </Link>
              &nbsp;
              <Button type="submit" variant="primary">
                Verify
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </>
  );
};

export default Login;
