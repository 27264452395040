import React from "react";

function Contact() {
  return (
    <div className="home_middle_bottom">
      <div className="contact_main">
        <h6>
          If you have any questions about these Terms, please contact us on
          +91-11-24626517, +91-11-24641804, +91-9911124477 or mail us on
          anandsuperfood@gmail.com
        </h6>
      </div>
    </div>
  );
}
export default Contact;
