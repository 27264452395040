import React from "react";
import { CircularProgress } from "@mui/material";

function CircularProgressIndicator() {
  return (
    <center>
      <CircularProgress />
    </center>
  );
}
export default CircularProgressIndicator;
