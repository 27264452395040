import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyDBBqwnXG3x_uc0nqGjH3VGzR3jVLR0gBU",
  authDomain: "anand-general-store-a99db.firebaseapp.com",
  projectId: "anand-general-store-a99db",
  storageBucket: "anand-general-store-a99db.appspot.com",
  messagingSenderId: "163329581854",
  appId: "1:163329581854:web:90cb26ac5da65895461f0f"
};

const app = initializeApp(firebaseConfig);
const storage = getStorage();
const db = getFirestore(app);
export { db, storage, app };
