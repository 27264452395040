import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import {BrowserRouter} from "react-router-dom"
import reportWebVitals from "./reportWebVitals";
import StoreProvider from "./utlis/StoresContact";

ReactDOM.render(
 <BrowserRouter> <StoreProvider childern={<App />}></StoreProvider></BrowserRouter>,
  document.getElementById("root")
);

reportWebVitals();
