import React from "react";
import PrivacyImg from "../images/privacypolicy.png";
function Privacy() {
  return (
    <div className="home_middle_bottom">
      <h3 className="privacy_header">Privacy Policy</h3>
      <h5 className="privacy_policy">
        Anand General Store (“Anand General Store”, "us", "we", or "our")
        operates the https://anandgeneralstores.com website and the Anand
        General Store mobile application (the "Service").
      </h5>

      <h5 className="privacy_policy">
        This page informs you of our policies regarding the collection, use, and
        disclosure of personal data when you use our Service and the choices you
        have associated with that data.
      </h5>
      <h5 className="privacy_policy">
        We use your data to provide and improve the Service. By using the
        Service, you agree to the collection and use of information in
        accordance with this policy. Unless otherwise defined in this Privacy
        Policy, terms used in this Privacy Policy have the same meanings as in
        our Terms and Conditions.
      </h5>
      <h5 className="privacy_policy">Information Collection and Use</h5>
      <h5 className="privacy_policy">
        We collect several different types of information for various purposes
        to provide and improve our Service to you.
      </h5>
      <h5 className="privacy_policy">Types of Data Collected</h5>
      <h5 className="privacy_policy">Personal Data</h5>
      <h5 className="privacy_policy">
        While using our Service, we may ask you to provide us with certain
        personally identifiable information that can be used to contact or
        identify you ("Personal Data"). Personally, identifiable information may
        include, but is not limited to:
      </h5>
      <h5 className="privacy_policy">
        Email address
        <br />
        First name and last name
        <br />
        Phone number
        <br />
        Address, State, Province, ZIP/Postal code, City
        <br />
        Cookies and Usage Data
        <br />
      </h5>
      <h5 className="privacy_policy">Usage Data</h5>
      <h5 className="privacy_policy">
        We may also collect information that your browser sends whenever you
        visit our Service or when you access the Service by or through a mobile
        device ("Usage Data").
      </h5>
      <h5 className="privacy_policy">
        This Usage Data may include information such as your computer's Internet
        Protocol address (e.g. IP address), browser type, browser version, the
        pages of our Service that you visit, the time and date of your visit,
        the time spent on those pages, unique device identifiers and other
        diagnostic data.
      </h5>
      <h5 className="privacy_policy">
        When you access the Service by or through a mobile device, this Usage
        Data may include information such as the type of mobile device you use,
        your mobile device unique ID, the IP address of your mobile device, your
        mobile operating system, the type of mobile Internet browser you use,
        unique device identifiers and other diagnostic data.
      </h5>
      <h5 className="privacy_policy">Tracking & Cookies Data</h5>
      <h5 className="privacy_policy">
        We use cookies and similar tracking technologies to track the activity
        on our Service and hold certain information.
      </h5>
      <h5 className="privacy_policy">
        Cookies are files with small amount of data which may include an
        anonymous unique identifier. Cookies are sent to your browser from a
        website and stored on your device. Tracking technologies also used are
        beacons, tags, and scripts to collect and track information and to
        improve and analyze our Service.
      </h5>
      <h5 className="privacy_policy">
        You can instruct your browser to refuse all cookies or to indicate when
        a cookie is being sent. However, if you do not accept cookies, you may
        not be able to use some portions of our Service.
      </h5>
      <h5 className="privacy_policy">Examples of Cookies we use:</h5>
      <h5 className="privacy_policy">
        Session Cookies. We use Session Cookies to operate our Service.
        <br />
        Preference Cookies. We use Preference Cookies to remember your
        preferences and various settings.
        <br />
        Security Cookies. We use Security Cookies for security purposes.
        <br />
      </h5>
      <h5 className="privacy_policy">Use of Data</h5>
      <div className="privacy_img_div">
        <img src={PrivacyImg} alt="" className="privacy_img" />
      </div>
      <h5 className="privacy_policy">Anand General Store uses the collected data for various purposes:</h5>
      <h5 className="privacy_policy">
        To provide and maintain the Service
        <br />
        To notify you about changes to our Service
        <br />
        To allow you to participate in interactive features of our Service when
        you choose to do so
        <br />
        To provide customer care and support
        <br />
        To provide analysis or valuable information so that we can improve the
        Service
        <br />
        To monitor the usage of the Service
        <br />
        To detect, prevent and address technical issues
        <br />
      </h5>
      <h5 className="privacy_policy">Transfer of Data</h5>
      <h5 className="privacy_policy">
        Your information, including Personal Data, may be transferred to — and
        maintained on — computers located outside of your state, province,
        country or other governmental jurisdiction where the data protection
        laws may differ than those from your jurisdiction.
      </h5>

      <h5 className="privacy_policy">
        If you are located outside India and choose to provide information to
        us, please note that we transfer the data, including Personal Data, to
        India and process it there.
      </h5>

      <h5 className="privacy_policy">
        Your consent to this Privacy Policy followed by your submission of such
        information represents your agreement to that transfer.
      </h5>

      
    </div>
  );
}
export default Privacy;
