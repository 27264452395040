import React, { useContext, useEffect, useState } from "react";
import vegitableImg from "../../images/ags-grid.png";
import cakeImg from "../../images/Cheetos.png";
import { StoreContext } from "../../utlis/StoresContact";
import snackImg from "../../images/Samyang1.png";
import freshfruitImg from "../../images/Samyang.png";
import bread from "../../images/bread-grid.png";
import CarouselBanner from "../Banner";
import PosterImage from "../../images/banner.PNG";
import { getDocs, collection, limit, query } from "firebase/firestore";
import { db } from "../../Firebase.js";
import CircularProgressIndicator from "../CircularProgressIndicator";

function HomeProducts() {
  const { current, category, productLoading } = useContext(StoreContext);
  const updateState = (value) => {
    current[1](value);
  };
  const [banner, setBanner] = useState();
  const [isloading, setIsLoading] = useState(true);
  const [categorys, setCategorys] = useState();
  const getCategory = async () => {
    const categorySnapshot = await getDocs(collection(db, "categories"));
    const categoryList = categorySnapshot.docs.map((doc) => doc.data());
    console.log(categoryList);
    setCategorys(categoryList);
  };
  const getBanner = async () => {
    const bannerSnapshot = await getDocs(
      query(collection(db, "banners"), limit(5))
    );
    const bannerList = bannerSnapshot.docs.map((doc) => doc.data());

    setBanner(bannerList);
  };

  const getData = async () => {
    await getBanner();
    await getCategory();
    setIsLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      {isloading ? (
        <div>
          <CircularProgressIndicator />
        </div>
      ) : (
        <>
          <div className="home_middle_carousel">
            <div className="carousel__container">
              <CarouselBanner urls={banner} />
            </div>
            <div className="carousel__container">
              <img src={PosterImage} alt="" className="poster-image" />
            </div>
          </div>
          <div className="home_middle_bottom">
            <div className="home_middle_sub_bottom">
              <div className="grid-container">
                <div className="breakfast">
                  <img
                    src={vegitableImg}
                    className="adv_grid_image"
                    alt="ags"
                    onClick={() => {
                      if (setCategorys !== null) {
                        productLoading[1](true);
                        category[1]("nVpDyrA8PvdMOphIU29Q");
                        updateState(1);
                      }
                    }}
                  />
                </div>
                <div className="bread">
                  <img
                    src={bread}
                    className="adv_grid_image"
                    alt="bread"
                    onClick={() => {
                      if (setCategorys !== null) {
                        productLoading[1](true);
                        category[1]("MSYyYHJQ4cIhdlXFffG6");
                        updateState(1);
                      }
                    }}
                  />
                </div>
                <div
                  className="cake"
                  onClick={() => {
                    if (setCategorys !== null) {
                      productLoading[1](true);
                      category[1]("TT4pUuYo3eLtIt8ICSzu7");
                      updateState(1);
                    }
                  }}
                >
                  <img
                    src={cakeImg}
                    alt="beverages"
                    className="adv_grid_image"
                  />
                </div>
                <div
                  className="tea"
                  onClick={() => {
                    if (setCategorys !== null) {
                      productLoading[1](true);
                      category[1]("VtovPOT7L5UvnlcNQT38");
                      updateState(1);
                    }
                  }}
                >
                  <img src={snackImg} alt="tea" className="adv_grid_image" />
                </div>
                <div
                  className="ags"
                  onClick={() => {
                    if (setCategorys !== null) {
                      productLoading[1](true);
                      category[1]("VtovPOT7L5UvnlcNQT38");
                      updateState(1);
                    }
                  }}
                >
                  <img
                    src={freshfruitImg}
                    alt="ags"
                    className="adv_grid_image"
                  />
                </div>
              </div>

              {/* <div
                className="home_middle_bottom_img"
                onClick={() => {
                  if (setCategorys !== null) {
                    productLoading[1](true);
                    category[1]("92ZXfU9ZFvCt4Rca9mBi");
                    updateState(1);
                  }
                }}
              >
                <img src={ExoticfruitImg} alt="gourmet" />
              </div> */}
              {/* <div
                className="home_middle_bottom_img"
                onClick={() => {
                  if (setCategorys !== null) {
                    productLoading[1](true);
                    category[1]("E6KUY0N1W6sFwYStLiD9");
                    updateState(1);
                  }
                }}
              >
                <img src={bakeryImg} alt="organic" />
              </div>
              <div
                className="home_middle_bottom_img"
                onClick={() => {
                  if (setCategorys !== null) {
                    productLoading[1](true);
                    category[1]("T4pUuYo3eLtIt8ICSzu7");
                    updateState(1);
                  }
                }}
              >
                <img src={EggImg} alt="snacks" />
              </div>
              <div
                className="home_middle_bottom_img"
                onClick={() => {
                  if (setCategorys !== null) {
                    productLoading[1](true);
                    category[1]("U37hwb3Wjzzet7wYSED2");
                    updateState(1);
                  }
                }}
              >
                <img src={coldCutImg} alt="chocolates" />
              </div>
              <div
                className="home_middle_bottom_img"
                onClick={() => {
                  if (setCategorys !== null) {
                    productLoading[1](true);
                    category[1]("UXr1w0aak2Hp2XXXgQqX");
                    updateState(1);
                  }
                }}
              >
                <img src={MuffinImg} alt="personalcare" />
              </div> */}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
export default HomeProducts;
