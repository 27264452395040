import React, { useState, useContext, useEffect } from "react";
import { AiOutlineHeart, AiFillHeart } from "react-icons/ai";
import { StoreContext } from "../../utlis/StoresContact";
import CircularProgressIndicator from "./../CircularProgressIndicator";

const getProductStatus = (product, selectedIndex, cart) => {
  let hashValue =
    product.productId +
    product.productItem[selectedIndex].productQuantity +
    product.productItem[selectedIndex].productQuantityType;
  for (let index = 0; index < cart.length; index++) {
    if (
      hashValue ===
      cart[index].productId +
        cart[index].productQuantity +
        cart[index].productQuantityType
    ) {
      return cart[index].orderedQuantity;
    }
  }
  return 0;
};

const getFavStatus = async (list, product) => {
  for (let index = 0; index < list.length; index++) {
    if (list[index] === product.productId) {
      return true;
    }
  }
  return false;
};

const Card = ({ product }) => {
  const { cartItems, likedList, likeFunction } = useContext(StoreContext);

  const [isLoading, setIsLoading] = useState(true);
  const [increment, setIncrement] = useState(0);
  const [like, setLike] = useState(false);
  const [price, setPrice] = useState(product.productItem[0].productPrice);
  const [selectedIndex, setSelectedIndex] = useState(0);

  const getData = async () => {
    setIncrement(getProductStatus(product, selectedIndex, cartItems[0]));
    setIsLoading(false);
  };

  const updateFav = async () => {
    var res = await getFavStatus(likedList[0], product, setLike);
    setLike(res);
  };

  useEffect(() => {
    updateFav();
  }, [likedList[0]]);

  useEffect(() => {
    getData();
  }, [cartItems[0], selectedIndex]);

  return (
    <div>
      {isLoading ? (
        <div>
          <CircularProgressIndicator />
        </div>
      ) : (
        <div className="card product_list">
          <div className="img_container">
            {!like && (
              <>
                <AiOutlineHeart
                  className="card_icon"
                  onClick={() => {
                    likeFunction[0](product);
                  }}
                />
              </>
            )}
            {like && (
              <>
                <AiFillHeart
                  className="card_icon"
                  onClick={() => {
                    likeFunction[1](product);
                  }}
                />
              </>
            )}

            
              <img
                src={product.productImage}
                className="card-img-top"
                alt="..."
              />
           
            <div className="card_content_div">
              <h5 className="card_title">{product.productName}</h5>
              <h5 className="card_text">Rs.{price}</h5>
            </div>
          </div>

          <div className="row card_btn">
            <select
              className="form-select form-select-lg mb-3 fruit_show"
              aria-label=".form-select-lg example"
              onChange={(e) => {
                for (var i = 0; i < product.productItem.length; i++) {
                  let productPrice =
                    product.productItem[i].productQuantity +
                    product.productItem[i].productQuantityType;
                  if (e.target.value === productPrice) {
                    setPrice(product.productItem[i].productPrice);
                    setSelectedIndex(i);
                    break;
                  }
                }
              }}
            >
              {product.productItem.map((item) => (
                <option key={product.productItem.indexOf(item)}>
                  {item.productQuantity}
                  {item.productQuantityType}
                </option>
              ))}
            </select>

            {increment > 0 && (
              <>
                <button
                  className="btn_card_dec"
                  onClick={(e) => {
                    e.preventDefault();
                    let hashValue =
                      product.productId +
                      product.productItem[selectedIndex].productQuantity +
                      product.productItem[selectedIndex].productQuantityType;
                    for (let index = 0; index < cartItems[0].length; index++) {
                      if (
                        hashValue ===
                        cartItems[0][index].productId +
                          cartItems[0][index].productQuantity +
                          cartItems[0][index].productQuantityType
                      ) {
                        if (cartItems[0][index].orderedQuantity === 1) {
                          cartItems[0].splice(index, 1);
                          cartItems[1]([...cartItems[0]]);
                          break;
                        } else {
                          let cart = {
                            productId: cartItems[0][index].productId,
                            productQuantity:
                              cartItems[0][index].productQuantity,
                            productPrice: cartItems[0][index].productPrice,
                            productQuantityType:
                              cartItems[0][index].productQuantityType,
                            orderedQuantity:
                              cartItems[0][index].orderedQuantity - 1,
                            totalPrice:
                              cartItems[0][index].totalPrice -
                              cartItems[0][index].productPrice,
                            productName: cartItems[0][index].productName,
                            productImage: cartItems[0][index].productImage,
                          };
                          cartItems[0].splice(index, 1);
                          cartItems[1]([...cartItems[0], cart]);
                          break;
                        }
                      }
                    }
                  }}
                >
                  -
                </button>

                <div className="btn_card_div">{increment}</div>

                <button
                  className="btn_card_inc"
                  onClick={(e) => {
                    e.preventDefault();
                    let hashValue =
                      product.productId +
                      product.productItem[selectedIndex].productQuantity +
                      product.productItem[selectedIndex].productQuantityType;
                    for (let index = 0; index < cartItems[0].length; index++) {
                      if (
                        hashValue ===
                        cartItems[0][index].productId +
                          cartItems[0][index].productQuantity +
                          cartItems[0][index].productQuantityType
                      ) {
                        let cart = {
                          productId: cartItems[0][index].productId,
                          productQuantity: cartItems[0][index].productQuantity,
                          productPrice: cartItems[0][index].productPrice,
                          productQuantityType:
                            cartItems[0][index].productQuantityType,
                          orderedQuantity:
                            cartItems[0][index].orderedQuantity + 1,
                          totalPrice:
                            cartItems[0][index].totalPrice +
                            cartItems[0][index].productPrice,
                          productName: cartItems[0][index].productName,
                          productImage: cartItems[0][index].productImage,
                        };
                        cartItems[0].splice(index, 1);
                        cartItems[1]([...cartItems[0], cart]);
                        break;
                      }
                    }
                  }}
                >
                  +
                </button>
              </>
            )}
            {increment === 0 && (
              <button
                className="btn_card_add"
                onClick={(e) => {
                  let cart = {
                    productId: product.productId,
                    productQuantity:
                      product.productItem[selectedIndex].productQuantity,
                    productPrice:
                      product.productItem[selectedIndex].productPrice,
                    productQuantityType:
                      product.productItem[selectedIndex].productQuantityType,
                    orderedQuantity: 1,
                    totalPrice: product.productItem[selectedIndex].productPrice,
                    productName: product.productName,
                    productImage: product.productImage,
                  };
                  cartItems[1]([...cartItems[0], cart]);
                }}
              >
                ADD
              </button>
            )}
          </div>
          {/* </div> */}
        </div>
      )}
    </div>
  );
};

export default Card;
