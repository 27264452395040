import React, { useState, useEffect } from "react";
import HomeProducts from "../Components/products/HomeProducts";
import About from "../Components/About";
import Privacy from "../Components/Privacy";
import StoreLocation from "../Components/StoreLocation";
import TermsAndConditions from "../Components/TermsAndConditions";
import Profile from "../Components/profile/profile";
import Address from "../Components/Address";
import Cart from "../Components/Cart";
import Product from "../Components/Product";
import SignInButton from "../authentication/SignInButton";
import AddAddress from "../Components/AddAddress";
import AddToFavourite from "../Components/AddToFavourite";
import PaymentSelection from "../Components/PaymentSelection";
import { getAuth } from "firebase/auth";
import {
  collection,
  addDoc,
  getDocs,
  deleteDoc,
  doc,
  query,
  where,
} from "firebase/firestore";
import { db } from "../Firebase.js";
import AddAddressWithoutCart from "../Components/AddAddressWithoutCart";
import OrderHistory from "../Components/OrderHistory";
import SearchScreen from "../Components/SearchScreen";
import Contact from "../Components/Contact";
import { RefundPolicy } from "../Components/RefundPolicy";

export const StoreContext = React.createContext(null);

const getFavouriteFromFirebase = async (auth) => {
  let userNumber = auth.currentUser.phoneNumber.substring(3, 13);
  const docRef = collection(db, "customers", userNumber, "favourites");
  const check = await getDocs(docRef);
  let favouriteList = check.docs.map((s) => s.data());
  favouriteList = favouriteList.map((s) => s.productId);
  return favouriteList;
};

const addFavouriteToFirebase = async (auth, product) => {
  let userNumber = auth.currentUser.phoneNumber.substring(3, 13);
  const docRef = collection(db, "customers", userNumber, "favourites");
  await addDoc(docRef, product);
};

const removeFavourite = async (auth, productId, product) => {
  let userNumber = auth.currentUser.phoneNumber.substring(3, 13);
  const docRef = collection(db, "customers", userNumber, "favourites");
  const check = await getDocs(docRef);
  let favouriteList = check.docs.map((s) => s.data());
  for (let index = 0; index < favouriteList.length; index++) {
    if (favouriteList[index].productId === productId) {
      const deletDoc = doc(
        db,
        "customers",
        userNumber,
        "favourites",
        check.docs[index].id
      );
      deleteDoc(deletDoc);
      break;
    }
  }
};

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ childern }) => {
  const Pages = [
    <HomeProducts />,
    <Product />,
    <Profile />,
    <About />,
    <StoreLocation />,
    <Privacy />,
    <TermsAndConditions />,
    <Address />,
    <Cart />,
    <SignInButton />,
    <AddAddress />,
    <AddToFavourite />,
    <PaymentSelection />,
    <AddAddressWithoutCart />,
    <OrderHistory />,
    <SearchScreen />,
    <Contact />,
    <RefundPolicy/>,
  ];
  const [current, setCurrent] = useState(0);
  const [categoryid, setCategoryId] = useState();
  const [isnumber, setIsNumber] = useState();
  const [cartItem, setCartItem] = useState([]);
  const [isProductPageLoading, setIsProductPageLoading] = useState(true);
  const [favList, setFavList] = useState([]);
  const [isFavListLoading, setIsFavListLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [issearching, setIssearching] = useState(false);
  const [searchedProduct, setSearchedProduct] = useState([]);

  var auth = getAuth();

  const searchProduct = async () => {
    setIssearching(true);
    const searchSnapshot = await getDocs(
      query(collection(db, "products"), where("productName", ">=", search))
    );
    const searchList = searchSnapshot.docs.map((doc) => doc.data());
    setSearchedProduct(searchList);
    setIssearching(false);
  };

  const getFavList = async () => {
    var localFavList = localStorage.getItem("fav");

    if (localFavList !== undefined && localFavList !== null) {
      var list = JSON.parse(localFavList);
      setFavList(list);
      await sinkData();
    } else {
      localStorage.removeItem("fav");
    }
  };

  const sinkData = async () => {
    let list = await getFavouriteFromFirebase(auth);
    setFavList(list);
    localStorage.setItem("fav", JSON.stringify(list));
  };

  const addToFav = async (product) => {
    if (auth.currentUser !== null) {
      var localFavList = localStorage.getItem("fav");

      if (localFavList !== undefined && localFavList !== null) {
        var list = JSON.parse(localFavList);
        list = [...list, product.productId];
        setFavList(list);
        localStorage.setItem("fav", JSON.stringify(list));
        await addFavouriteToFirebase(auth, product);
        await sinkData();
      } else {
        setFavList([product.productId]);
        localStorage.setItem("fav", JSON.stringify([product.productId]));
        await addFavouriteToFirebase(auth, product);
        await sinkData();
      }
    } else {
      alert("Login to add to fav");
    }
  };
  const removeFav = async (product) => {
    if (auth.currentUser !== null) {
      var localFavList = localStorage.getItem("fav");

      if (
        localFavList !== undefined &&
        localFavList !== null &&
        favList.length > 0
      ) {
        for (let index = 0; index < favList.length; index++) {
          if (favList[index] === product.productId) {
            let list = favList;
            list.splice(index, 1);
            list = [...list];
            setFavList(list);
            localStorage.setItem("fav", JSON.stringify(list));
            await removeFavourite(auth, product.productId, product);
            await sinkData();
          }
        }
      }
    }
  };

  const cartData = async () => {
    var store = localStorage.getItem("cart");
    if (
      cartItem.length === 0 &&
      store !== undefined &&
      store !== null &&
      JSON.parse(store).length > 0
    ) {
      setCartItem(JSON.parse(store));
    } else if (cartItem.length > 0) {
      localStorage.setItem("cart", JSON.stringify(cartItem));
    } else {
    }
  };

  const updateAddress = (add) => {
    localStorage.setItem("address", add);
  };

  const getAddress = () => {
    var add = localStorage.getItem("address");
    if (store !== undefined && store !== null) {
      return add;
    } else {
      alert("Please select valid address");
    }
  };

  useEffect(() => {
    cartData();
  }, [cartItem]);

  useEffect(() => {
    getFavList();
  }, [auth]);

  const store = {
    current: [current, setCurrent],
    Pages,
    category: [categoryid, setCategoryId],
    productLoading: [isProductPageLoading, setIsProductPageLoading],
    phonenumber: [isnumber, setIsNumber],
    cartItems: [cartItem, setCartItem],
    likedList: [favList, setFavList],
    islikedLoading: [isFavListLoading, setIsFavListLoading],
    likeFunction: [addToFav, removeFav],
    address: [getAddress, updateAddress],
    searchItem: [search, setSearch],
    searchLoading: [issearching, setIssearching],
    issearchProduct: [searchedProduct, setSearchedProduct],
    searchfunction: [searchProduct],
  };
  return (
    <StoreContext.Provider value={store}>{childern}</StoreContext.Provider>
  );
};
