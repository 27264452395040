import React from "react";

export const RefundPolicy = () => {
  return (
    <div className="home_middle_bottom">
      <h4>Returns and Refund Policy</h4>
      <h6>
        Thank you for purchasing at any of our Anand General Store or through
        our online marketplace.
      </h6>
      <h4>Returns</h4>
      <h6>
        You have 7 number of days to return an item from the date that you
        purchase it.
      </h6>

      <h6>
        To be eligible for a return here at Anand General Store, the product
        that you purchased must be unused, still in its original packaging, and
        in the same condition as when you purchased it.
      </h6>
      <h6>
        We also require the original receipt or proof of purchase to be eligible
        for a return.
      </h6>
      <h6>Note:</h6>
      <h6>
        Due to the nature of our business, several types of goods are exempt
        from being returned. Perishable goods such as Dairy, Refrigerated, Oil,
        Ghee, Flours & Bread products that are intimate or sanitary goods,
        hazardous materials, flammable liquids or gases and similar products.
      </h6>
      <h6>
        Certain other non-returnable products are Gift cards, free offer
        products and certain health/ personal care products
      </h6>
      <h4>Refunds</h4>
      <h6>
        Once we have received your purchased product, we will let you know that
        we’ve received it and that we are in the process of evaluating if it’s
        in the same condition as when it was delivered to you. We will let you
        know the status of your refund as soon as we have finished inspecting
        your purchased product.
      </h6>
      <h6>
        If we approve your refund, we will refund it to MB Wallet of your
        respective account.
      </h6>
      <h6>
        Only regular priced products may be refunded, products purchased under
        “Sale” can’t be refunded.
      </h6>
      <h4>Cancellation:</h4>
      <h6>
        If you have purchased through our online marketplace, if your refund is
        approved, then your refund will be processed, and a credit will
        automatically be applied to your AGS Wallet, within 7 days.
      </h6>
      <h4>Exchanges (if applicable)</h4>
      <h6>
        We only replace products when they are defective or damaged at the time
        of purchase. If you need to exchange it for the same product, send us an
        email at anandsuperfood@gmail.com
      </h6>
      <h4>Shipping</h4>
      <h6>
        You will be responsible for paying the costs of shipping or bring back
        your purchased product back to us. The costs of shipping are
        non-refundable and non-negotiable. If you are issued a refund, the cost
        of shipping will be deducted from it.
      </h6>
      <h6>
        If you have any further question regarding your refund, please, don’t
        hesitate to contact us to find out more at anandsuperfood@gmail.com
      </h6>
    </div>
  );
};
