import React, { useContext } from "react";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";
import NavbarCollapse from "react-bootstrap/esm/NavbarCollapse";
import NavbarToggle from "react-bootstrap/esm/NavbarToggle";
import { StoreContext } from "../utlis/StoresContact";

function SiteNavbar(props) {
  const { current } = useContext(StoreContext);
  return (
    <Navbar
      bg="transparent"
      variant="dark"
      style={{
        color: "black",
        zIndex: "10",
        padding: "0.3rem",
      }}
      expand="sm"
    >
      <Container fluid>
        <NavbarToggle
          aria-controls="basic-navbar-nav"
          className="navbar_toggle"
        />
        <NavbarCollapse id="basic-navbar-nav">
          <Nav className="navbar_list ms-auto gap-3">
            <Nav.Link className="navbar_content">
              <div className="navbar_text" onClick={() => current[1](3)}>
                About Us
              </div>
            </Nav.Link>

            <NavDropdown
              title="Quick Links"
              className="navbar_content"
              id="basic-nav-dropdown"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item onClick={() => current[1](16)}>
                Contact us
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => current[1](4)}>
                Stores
              </NavDropdown.Item>
            </NavDropdown>

            <NavDropdown
              title="Help & Support"
              className="navbar_content"
              id="basic-nav-dropdown"
              renderMenuOnMount={true}
            >
              <NavDropdown.Item onClick={() => current[1](5)}>
                Privacy Policy
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => current[1](6)}>
                Terms & Conditions
              </NavDropdown.Item>
              <NavDropdown.Item onClick={() => current[1](17)}>
                Refund Policy
              </NavDropdown.Item>
            </NavDropdown>
          </Nav>
        </NavbarCollapse>
      </Container>
    </Navbar>
  );
}

export default SiteNavbar;
