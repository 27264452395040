import React, { useState, useEffect, useContext } from "react";
import { StoreContext } from "../utlis/StoresContact";
import { getAuth } from "firebase/auth";
import { collection, addDoc, getDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../Firebase.js";
import CircularProgressIndicator from "./CircularProgressIndicator";

function loadRazyorPay() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";

    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}

const PaymentSelection = () => {
  const { cartItems, current, address } = useContext(StoreContext);
  const [isLoading, setisLoading] = useState(true);
  const [user, setUser] = useState({});
  const [cartTotal, setcartTotal] = useState(0);
  const [payAmount, setPayAmount] = useState(0);
  const [wallet, setWallet] = useState(0);
  const [isWalletSelected, setIsWalletSelected] = useState(true);
  var auth = getAuth();

  const setCartTotal = () => {
    var total = 0;
    cartItems[0].forEach((element) => {
      total = total + element.totalPrice;
    });
    setcartTotal(total.toFixed(2));
    setPayAmount(total.toFixed(2));
  };

  const placeOrderHandler = () => {
    // create an order
    handlePayment();
  };
  async function handlePayment() {
    const res = await loadRazyorPay();
    let userNumber = auth.currentUser.phoneNumber.substring(3, 13);

    if (!res) {
      alert("Razorpay sdk failed");
      return;
    }
    var options = {
      key: "rzp_live_GGkaDrkonk8fCc",
      amount: payAmount * 100,
      currency: "INR",
      name: "Anand General Store",
      description: "Anand General Store-Order Transaction",
      image:
        "https://images.unsplash.com/photo-1646725726155-055331ad5d95?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=987&q=80",
      handler: function (response) {
        if (
          response.razorpay_payment_id !== undefined &&
          response.razorpay_payment_id !== null
        ) {
          createOrder();
        }
        // alert("payment id"); //response.razorpay_payment_id //! check this
        // alert("order id "); //response.razorpay_order_id,
        // alert("razorpay_signature"); //response.razorpay_signature
      },
      prefill: {
        name: localStorage.getItem("customerName"),
        contact: userNumber,
      },
      notes: {
        address: "Anand General Store",
      },
      theme: {
        color: "#3399cc",
      },
    };
    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }
  const getWalletBalance = async () => {
    setisLoading(true);
    setCartTotal();
    let userNumber = auth.currentUser.phoneNumber.substring(3, 13);
    const docRef = doc(db, "customers", userNumber);
    const check = await getDoc(docRef);
    setUser(check.data());
    setWallet(check.data().wallet);
    setisLoading(false);
  };

  const updatePrice = () => {
    if (isWalletSelected) {
      setIsWalletSelected(!isWalletSelected);
      setPayAmount(cartTotal.toFixed(2));
    } else {
      setIsWalletSelected(!isWalletSelected);
      if (wallet >= cartTotal) {
        setPayAmount(0.0);
      } else {
        setPayAmount((cartTotal - wallet).toFixed(2));
      }
    }
  };

  const createOrder = async () => {
    setisLoading(true);
    let userNumber = auth.currentUser.phoneNumber.substring(3, 13);
    let userName = localStorage.getItem("customerName");
    let userAddress = address[0]();

    const docRef = await addDoc(collection(db, "orders"), {
      customerAddress: userAddress,
      customerId: userNumber,
      customerName: userName,
      isAccepted: false,
      isCancel: false,
      isDelivered: false,
      orderStatus: "Pending",
      totalAmount: payAmount,
      items: cartItems[0],
    });

    let id = docRef._key.path.segments[1];
    try {
      await updateDoc(doc(collection(db, "orders"), id), {
        orderId: id,
      });
      localStorage.removeItem("cart");

      cartItems[1]([]);
      current[1](8);
      alert("Order created success!");
    } catch (error) {
      alert("please try again!");
    }

    setisLoading(false);
  };
  useEffect(() => {
    getWalletBalance();
  }, []);

  return (
    <div>
      {isLoading ? (
        <div>
          {" "}
          <CircularProgressIndicator />{" "}
        </div>
      ) : (
        <div className="payment_selection_container">
          <button
            className={
              isWalletSelected ? "selectedWallet" : "un-selectedWallet"
            }
            onClick={(e) => {
              e.preventDefault();
              updatePrice();
            }}
          >
            ₹{wallet}
          </button>

          <button
            onClick={(e) => {
              e.preventDefault();
              if (payAmount > 0) {
                placeOrderHandler();
              } else {
                createOrder();
              }
            }}
          >
            {" "}
            {payAmount} Pay now
          </button>
        </div>
      )}
    </div>
  );
};

export default PaymentSelection;
