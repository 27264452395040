import React, { useEffect, useState, useContext } from "react";
import { getDocs, collection, query, where } from "firebase/firestore";
import { db } from "../Firebase";
import { StoreContext } from "../utlis/StoresContact";
import Card from "./products/Card";
import Filter from "./filter";
import CircularProgressIndicator from "./CircularProgressIndicator";

function shuffle(array) {
  let currentIndex = array.length,
    randomIndex;

  while (currentIndex !== 0) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    [array[currentIndex], array[randomIndex]] = [
      array[randomIndex],
      array[currentIndex],
    ];
  }

  return array;
}

export default function Product() {
  let id = "";

  const { category, productLoading } = useContext(StoreContext);
  const [product, setProduct] = useState([]);

  const getproduct = async () => {
    id = category[0];
    const productSnapshot = await getDocs(
      query(collection(db, "products"), where("productCategoryId", "==", id))
    );
    const productList = productSnapshot.docs.map((doc) => doc.data());
    setProduct(productList);
    productLoading[1](false);
  };

  useEffect(() => {
    getproduct();
  }, [productLoading[0]]);

  return (
    <div className="home_middle_bottom">
      <div>
        {productLoading[0] ? (
          <div>
            <CircularProgressIndicator />
          </div>
        ) : (
          <div>
            <Filter
              relevance={() => {
                var p = shuffle(product);
                setProduct([...p]);
              }}
              lowToHigh={() => {
                var p = product.sort(
                  (a, b) =>
                    product[product.indexOf(a)].productItem[0].productPrice -
                    product[product.indexOf(b)].productItem[0].productPrice
                );
                setProduct([...p]);
              }}
              highToLow={() => {
                var p = product.sort(
                  (a, b) =>
                    product[product.indexOf(a)].productItem[0].productPrice -
                    product[product.indexOf(b)].productItem[0].productPrice
                );
                p = p.reverse();
                setProduct([...p]);
              }}
            />
            <div className="product_div">
              {product &&
                product.map((p) => <Card product={p} key={p.productId} />)}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
