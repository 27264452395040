import React, { useContext } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { StoreContext } from "../utlis/StoresContact";


const responsive = {
  0: { items: 3 },
  568: { items: 3 },
  800: {items : 3},
  1024: { items: 6 },
};

const MultiCarousel = ({ names, isClickable, setCurrent, setCategorys }) => {
  const { current, productLoading, category } = useContext(StoreContext);

  const items = [];
  names.map((name, index) =>
    items.push(
      <div
        className="category_carousel"
        key={index}
        onClick={() => {
          if (setCategorys !== null) {
            console.log("1");
            productLoading[1](true);
            category[1](name.categoryId);
            current[1](1);
          } else {
            console.log("2");
          }
        }}
      >
        <div>
          <img src={name.categoryImage} alt="" className="circle" />
          <div className="multi_carousel_name">{name.categoryName}</div>
        </div>
      </div>
    )
  );
  return (
    <AliceCarousel
      disableDotsControls
      infinite
      mouseTracking
      items={items}
      responsive={responsive}
      autoPlay={false}
    />
  );
};
MultiCarousel.defaultProps = { isClickable: false };

export default MultiCarousel;
