import React from "react";
import StoreImg from "../images/store.png";
import { GoLocation } from "react-icons/go";
import { FiPhoneCall } from "react-icons/fi";
import Map from "./Map";

function StoreLocation() {
  return (
    <div className="home_middle_bottom">
      <div className="store_header">
        <h3 className="store_location_header">STORES</h3>
      </div>
      <div className="store_location">
        <Map />
      </div>
      <div className="store_location_bottom">
        <img src={StoreImg} alt="" />
        <div className="store_header">
          <div className="store_location_bottom_content">
            <h4 className="store_location_bottom_content_header">
              Anand General Store, Khan Market
            </h4>
            <div className="store_location_pointing">
              <GoLocation size={24} className="golocation-icon" />
              <h5 className="store_location_bottom_content_address">
                63-A, Khan Market, Rabindra Nagar, New Delhi, Delhi 110003
              </h5>
            </div>
            <div className="store_location_pointing">
              <FiPhoneCall size={20} className="golocation-icon"/>
             <h6 className="store_location_bottom_content_address">+91-9911124477</h6> 
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default StoreLocation;
