import React, { useContext, useEffect } from "react";
import { StoreContext } from "../utlis/StoresContact";
import CircularProgressIndicator from "../Components/CircularProgressIndicator";
import Card from "../Components/products/Card";



function SearchScreen() {
  const { searchItem, searchLoading, issearchProduct } =
    useContext(StoreContext);

  useEffect(() => {}, [searchLoading[0]]);
  return (
    <>
      <div className="home_middle_bottom">
        <div>
          {searchLoading[0] ? (
            <CircularProgressIndicator />
          ) : (
            <div className="product_div">
              {issearchProduct[0].length > 0 ? (
                issearchProduct[0].map((p) => (
                  <Card product={p} key={p.productId} />
                ))
              ) : (
                <div>No Product Found</div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
}
export default SearchScreen;
