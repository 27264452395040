import React, { useState, useContext } from "react";
import { doc, collection, addDoc } from "firebase/firestore";
import { db } from "../Firebase";
import { getAuth } from "firebase/auth";
import { StoreContext } from "../utlis/StoresContact";
import CircularProgressIndicator from "./CircularProgressIndicator";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
function AddAddress({ number }) {
  const { current } = useContext(StoreContext);
  const [house, setHouse] = useState();
  const [floor, setFloor] = useState();
  const [area, setArea] = useState();
  const [landmark, setLandmark] = useState();
  const [city, setCity] = useState();
  const [state, setState] = useState();
  const [pincode, setPincode] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const auth = getAuth();

  const AddressSubmit = async () => {
    setIsLoading(true);
    let userNumber = auth.currentUser.phoneNumber.substring(3, 13);
    let floorvalue =
      floor === "1"
        ? "1st Floor"
        : floor === "2"
        ? "2nd Floor"
        : floor === "3"
        ? "3rd Floor"
        : floor + "th floor";

    let y =
      "House No:\t" +
      house +
      "\n" +
      floorvalue +
      "\n" +
      area +
      "\n" +
      "Landmark:\t" +
      landmark +
      "\n" +
      city +
      "\n" +
      state +
      "\t" +
      "-\t" +
      pincode;

    const docRef = await doc(db, "customers", userNumber);
    const colRef = await collection(docRef, "address");
    addDoc(colRef, {
      address: y,
    });
    current[1](7);
    setIsLoading(false);
  };

  return (
    <div className="home_middle_bottom">
      {isLoading ? (
        <div>
          <CircularProgressIndicator />
        </div>
      ) : (
        <div>
          <div className="add_address_container">
            <Box
              className="box_input"
              component="form"
              sx={{
                "& > :not(style)": { m: 1, width: "40ch" },
              }}
              noValidate
              autoComplete="off"
            >
              <TextField
                id="outlined-basic"
                label="House No"
                variant="outlined"
                value={house}
                onChange={(e) => setHouse(e.target.value)}
                type="text"
                placeholder="Enter Your House no"
              />
              <TextField
                id="outlined-basic"
                label="Floor No"
                variant="outlined"
                value={floor}
                onChange={(e) => setFloor(e.target.value)}
                type="text"
                placeholder="Enter Your Floor no"
              />
              <TextField
                id="outlined-basic"
                label="Area"
                variant="outlined"
                value={area}
                onChange={(e) => setArea(e.target.value)}
                type="text"
                placeholder="Enter Your Area"
              />
              <TextField
                id="outlined-basic"
                label="Landmark"
                variant="outlined"
                value={landmark}
                onChange={(e) => setLandmark(e.target.value)}
                type="text"
                placeholder="Enter Your Landmark"
              />
              <TextField
                id="outlined-basic"
                label="City"
                variant="outlined"
                value={city}
                onChange={(e) => setCity(e.target.value)}
                type="text"
                placeholder="Enter Your City"
              />
              <TextField
                id="outlined-basic"
                label="State"
                variant="outlined"
                value={state}
                onChange={(e) => setState(e.target.value)}
                type="text"
                placeholder="Enter Your State"
              />
              <TextField
                id="outlined-basic"
                label="Pincode"
                variant="outlined"
                value={pincode}
                onChange={(e) => setPincode(e.target.value)}
                type="text"
                placeholder=" Enter Area Pincode"
              />
            </Box>

            <div className="add_address_btn_div">
              <button
                className="add_address_btn"
                onClick={(e) => {
                  e.preventDefault();
                  AddressSubmit();
                }}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
export default AddAddress;
