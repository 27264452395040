import React, { useContext, useState , useEffect} from "react";
import { StoreContext } from "../utlis/StoresContact";
import Fb from "../images/fb.png"
import Insta from "../images/insta.png";
import { getDocs, collection } from "firebase/firestore";
import { db } from "../Firebase.js";
function Footer() {
  const { current, category, productLoading } = useContext(StoreContext);
  const updateState = (value) => {
    current[1](value);
  };
  const [categorys, setCategorys] = useState();
  const getCategory = async () => {
    const categorySnapshot = await getDocs(collection(db, "categories"));
    const categoryList = categorySnapshot.docs.map((doc) => doc.data());
    console.log(categoryList);
    setCategorys(categoryList);
  };

  useEffect(() => {
    getCategory();
  }, []);
  return (
    <div className="footer">
      <div className="footer__container">
        <div className="footer__container_logo_contianer">
          <div className="footer__container_logo_contianer_details">
            63-A, Khan Market, Rabindra Nagar, New Delhi 110003
          </div>
          <div className="footer__container_logo_contianer_details">
            <a href="tel:+91-9911124477">+91-9911124477</a>
          </div>
          <div className="footer__container_logo_contianer_details">
            <a href="mailto:anandsuperfood@gmail.com">
              anandsuperfood@gmail.com
            </a>
          </div>
        </div>
        <div className="footer__container_links_contianer">
          <div className="footer__subcontainer">
          <div className="footer__container_links_contianer_content_wraper">
            <div className="footer__container_links_contianer_title">
              EXPLORE
            </div>
            <div className="footer__container_links_contianer_list">
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => current[1](0)}
                >
                  Home
                </div>
              </div>
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => current[1](3)}
                >
                  About Us
                </div>
              </div>
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => current[1](4)}
                >
                  Stores
                </div>
              </div>
            </div>
          </div>
          <div className="footer__container_links_contianer_content_wraper">
            <div className="footer__container_links_contianer_title">
              CATEGORIES
            </div>
            <div className="footer__container_links_contianer_list">
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => {
                    if (setCategorys !== null) {
                      productLoading[1](true);
                      category[1]("E6KUY0N1W6sFwYStLiD9");
                      updateState(1);
                    }
                  }}
                >
                 Organic
                </div>
              </div>
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => {
                    if (setCategorys !== null) {
                      productLoading[1](true);
                      category[1]("MSYyYHJQ4cIhdlXFffG6");
                      updateState(1);
                    }
                  }}
                >
                  Breakfast
                </div>
              </div>
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => {
                    if (setCategorys !== null) {
                      productLoading[1](true);
                      category[1]("T4pUuYo3eLtIt8ICSzu7");
                      updateState(1);
                    }
                  }}
                >
                  Snacks
                </div>
              </div>
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => {
                    if (setCategorys !== null) {
                      productLoading[1](true);
                      category[1]("TDmz1zFHUmDmD5uCfuND");
                      updateState(1);
                    }
                  }}
                >
                  Beverages
                </div>
              </div>
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => {
                    if (setCategorys !== null) {
                      productLoading[1](true);
                      category[1]("U37hwb3Wjzzet7wYSED2");
                      updateState(1);
                    }
                  }}
                >
                  Chocolates
                </div>
              </div>
            </div>
          </div>
          </div>
          <div className="footer__subcontainer">
          <div className="footer__container_links_contianer_content_wraper">
            <div className="footer__container_links_contianer_title">
              LEGALS
            </div>
            <div className="footer__container_links_contianer_list">
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => current[1](6)}
                >
                  Terms and Conditions
                </div>
              </div>
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => current[1](5)}
                >
                  Privacy Privacy
                </div>
              </div>
              <div>
                <div
                  className="footer__container_links_contianer_list_item"
                  onClick={() => current[1](17)}
                >
                  Refund Policy
                </div>
              </div>
            </div>
          </div>
          <div className="footer__container_links_contianer_content_wraper">
            <div className="footer__container_links_contianer_title">
              Social Network
            </div>
            <div className="footer__container_links_contianer_list">
              <div className="footer_social_media_pink">
                <div>
                  <a
                    target="_blank"
                    href="https://www.facebook.com/"
                    rel="noopener noreferrer"
                  >
                    <div className="facebook">
                      <img
                        src={Fb}
                        alt="facebook"
                      />
                    </div>
                  </a>
                </div>
                <div>
                  <a
                    target="_blank"
                    href="https://instagram.com/"
                    rel="noopener noreferrer"
                  >
                    <div className="instagram">
                      <img
                        src={Insta}
                        alt="instagram"
                      />
                    </div>
                  </a>
                </div>
              </div>
              <div>
                <h5>Download Our App</h5>
              </div>
              <div>
                <a href="https://play.google.com/store/apps/details?id=com.ags.anand_general_store">
                  <img
                    src="https://cdn.modernbazaar.online/assets/img/google_play.png"
                    alt="google play"
                  />
                </a>
              </div>
              <br />
              <div>
                <img
                  src="https://cdn.modernbazaar.online/assets/img/app_store.png"
                  alt="ios"
                />
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
