import React, { useContext, useState, useEffect } from "react";
import { AiOutlineShoppingCart } from "react-icons/ai";
import { IconContext } from "react-icons";
import { StoreContext } from "../utlis/StoresContact";

export default function Cart() {
  const { cartItems, current } = useContext(StoreContext);
  const [cartTotal, setcartTotal] = useState(0);

  const setCartTotal = () => {
    var total = 0;
    cartItems[0].forEach((element) => {
      total = total + element.totalPrice;
    });
    setcartTotal(total.toFixed(2));
  };

  useEffect(() => {
    setCartTotal();
  }, [cartItems[0]]);

  return (
    <>
      <div className="home_middle_bottom">
        <div className="cart_header">
          <div className="row cart">
            <div className="cart_header_div">
              <IconContext.Provider value={{ color: "grey", size: 30 }}>
                <AiOutlineShoppingCart className="cart_icon" />
                <h3>
                  Your Cart Value:
                  <span className="cart_price_value">Rs.{cartTotal}</span>
                </h3>
              </IconContext.Provider>
              <div className="cart_content">
                <button
                  className="cart_header_btn"
                  onClick={() => {
                    current[1](7);
                  }}
                >
                  Next
                </button>
              </div>
            </div>

            {/* <div className="cart_content">
              <button
                className="cart_header_btn"
                onClick={() => {
                  current[1](7);
                }}
              >
                Next
              </button>
            </div> */}
          </div>
        </div>

        <div className="cart_order_div">
          {cartItems[0] &&
            cartItems[0].map((cart) => (
              <div className="cart_order">
                <img
                  src={cart.productImage}
                  alt=""
                  className="cart_product_image"
                />
                <div className="col-6 ">
                  <h5 className="cart-productname">{cart.productName}</h5>
                  <div className="cart-name-col">
                    <h6>Rs.{cart.productPrice}</h6>
                    <h6>
                      Quantity:
                      {cart.productQuantity + cart.productQuantityType}
                    </h6>
                  </div>
                </div>
                <div className="col cart_order_price">
                  <h5 className="cart-productname">Rs. {cart.totalPrice}</h5>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
}
