import React, { useState, useEffect } from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";
import TimelineDot from "@mui/lab/TimelineDot";
import {
  MdDeliveryDining,
  MdOutlineDone,
  MdOutlineDoneAll,
} from "react-icons/md";
import CircularProgressIndicator from "./CircularProgressIndicator";

import {
  doc,
  getDocs,
  collection,
  query,
  where,
  updateDoc,
} from "firebase/firestore";
import { db } from "../Firebase";
import { getAuth } from "firebase/auth";

function OrderHistory() {
  const auth = getAuth();
  const [isloading, setIsLoading] = useState(true);
  const [order, setOrder] = useState();
  let customerId = auth.currentUser.phoneNumber.substring(3, 13);
  const getOrder = async () => {
    const orderSnapshot = await getDocs(
      query(collection(db, "orders"), where("customerId", "==", customerId))
    );
    const orderList = orderSnapshot.docs.map((doc) => doc.data());
    setOrder(orderList);
    setIsLoading(false);
  };

  const cancelOrder = async (orderId) => {
    setIsLoading(true);
    await updateDoc(doc(collection(db, "orders"), orderId), {
      isCancel: true,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    getOrder();
  }, []);

  return (
    <div className="home_middle_bottom">
      {isloading ? (
        <div>
          <CircularProgressIndicator />
        </div>
      ) : (
        <>
          <h3>Order History</h3>
          <div className="order_history_main">
            {order.map((odd) => (
              <>
                {odd.items.map((item) => (
                  <div className="order_history">
                    <div className="col-6 order_history_content">
                      <img
                        src={item.productImage}
                        alt=""
                        className="order_history_img"
                      />
                      <div className="order_history_detail">
                        <p>{odd.orderId}</p>
                        <p>{item.productName}</p>
                        <p>
                          Quantity: {item.productQuantity}
                          {item.productQuantityType}
                        </p>
                        <p>Total Price: {item.totalPrice}</p>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="order_right_div">
                        {odd.isAccepted === false &&
                        odd.isCancel === false &&
                        odd.isDelivered === false ? (
                          <button
                            className="order_cancel_btn"
                            onClick={(e) => {
                              e.preventDefault();
                              cancelOrder(odd.orderId);
                            }}
                          >
                            Cancel
                          </button>
                        ) : (
                          <div></div>
                        )}

                        {odd.isCancel ? (
                          <div className="order_cancel">Cancelled</div>
                        ) : (
                          <div className="order_history_process">
                            <Timeline className="timeline" position="left">
                              <TimelineItem>
                                <TimelineSeparator>
                                  <TimelineDot>
                                    <MdOutlineDone className="mdoutlinedone" />
                                  </TimelineDot>
                                  <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent className="timeline_content"></TimelineContent>
                              </TimelineItem>
                              <TimelineItem>
                                <TimelineSeparator>
                                  <TimelineDot>
                                    <MdDeliveryDining
                                      className={
                                        odd.isAccepted ? "mddelivery" : ""
                                      }
                                    />
                                  </TimelineDot>
                                  <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent className="timeline_content"></TimelineContent>
                              </TimelineItem>
                              <TimelineItem>
                                <TimelineSeparator>
                                  <TimelineDot>
                                    <MdOutlineDoneAll
                                      className={
                                        odd.isDelivered ? "mddoneall" : ""
                                      }
                                    />
                                  </TimelineDot>
                                </TimelineSeparator>
                                <TimelineContent className="timeline_content"></TimelineContent>
                              </TimelineItem>
                            </Timeline>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ))}
          </div>
        </>
      )}
    </div>
  );
}
export default OrderHistory;
